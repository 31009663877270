var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("v-template", [
    _c(
      "div",
      {
        staticClass: "row d-none d-md-block my-3",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "col d-flex justify-content-between align-items-center",
          },
          [
            _c(
              "p",
              {
                staticClass: "m-0 text-capitalize",
              },
              [_vm._v(_vm._s(_vm.title))]
            ),
            _c(
              "div",
              {
                staticClass: "w-75",
              },
              [_vm._t("default")],
              2
            ),
          ]
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "row d-sm-block d-md-none",
      },
      [
        _c(
          "div",
          {
            staticClass: "col",
          },
          [
            _c(
              "b-form-group",
              {
                attrs: {
                  label: _vm.title,
                },
              },
              [_vm._t("default")],
              2
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }